<template>
<div class="Settingsa page">
  <headers title="设置"></headers>
  <div class="scroll">
    <ul class="list">
      <li class="flexSpaceBetween" @click="toChat()">
        <div class="label">联系客服</div>
        <div class="right">
          <van-icon name="arrow" class="arrowRight" size="20" color="rgb(197, 197, 197)"/>
        </div>
      </li>
      <li class="flexSpaceBetween" @click="$router.push('/editTheme')">
        <div class="label">修改主题</div>
        <div class="right">
          <van-icon name="arrow" class="arrowRight" size="20" color="rgb(197, 197, 197)"/>
        </div>
      </li>
      <li class="flexSpaceBetween">
        <div class="label">当前版本</div>
        <div class="right">V3.0</div>
      </li>
    </ul>
    <div class="loginOut flexCenter" @click="loginOut()">退出登录</div>
  </div>
</div>
</template>

<script>
import headers from "@/components/headers.vue";

export default {
  name: "Settingsa",
  components: {headers},
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    loginOut(){
      this.$router.replace('/login')
      this.$store.commit('setToken', null)
      this.$store.commit('setFootIndex', 0)
      this.$store.commit('setUserInfo', {})
      this.$store.commit('setKefuUrl', '')
    },
    toChat(){
      window.location.href = this.$store.getters["getKefuUrl"]
    }
  }
}
</script>

<style scoped lang="scss">
.Settingsa {
  height: 100%;
  background: #f7f7fe;
  .scroll {
    .list {
      margin-top: 20px;
      background: #fff;
      padding: 0 15px;
      li {
        height: 55px;
        font-size: 16px;
        border-bottom: 1px solid #f0f0f0;
        &:last-child {
          border-bottom: none;
        }
      }
    }
    .loginOut {
      margin-top: 20px;
      background: #fff;
      height: 50px;
      font-size: 16px;
      justify-content: center;
    }
  }
}
</style>